import { PlusOutlined } from "@ant-design/icons"
import { Spin, Upload, message } from "antd"
import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"
import userApi from "src/api/userApi"
import { ComponentValidator } from "src/components/auth/Validator"
import { useGetData } from "src/hooks/useGetData"
import { getUser } from "src/libs/browser"
import { hasRequiredItem } from "src/libs/util"

export const EVENT_CHANGE_AVATAR = 'EVENT_CHANGE_AVATAR'
const userCurrent = getUser()

const headerChangeAvatarAction = {
    changeAvatar: (time) => {
      const ev = new CustomEvent(EVENT_CHANGE_AVATAR, { detail: { time } })
      document.dispatchEvent(ev)
    }
}

const AvatarProfile = () => {
    const [fileList, setFileList] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const initExperienceRef = useRef(false)
    const router = useRouter()
    
    const id = useMemo(() => {
        if (hasRequiredItem(userCurrent.userRoles, ['employee-teacher'])) {    
            return userCurrent.userId
        }
        return router.query?.id
    }, [router.query?.id, userCurrent])

    const { status, data: user, reload } = useGetData(userApi.detail, id, { isDisabled: !id })

    const initDataUserInfo = () => {
        if(user) {
            const { url } = user
            setFileList(
                url ? {
                    uid: '1',
                    name: url,
                    status: 'done',
                    url: `https://static.edupia.vn/${url}`,
                } : null
            )
        }
    }

    useEffect(() => {
        initDataUserInfo()
    }, [user, initExperienceRef.current])

    const handleChange = async (newFileList) => {
        setLoading(true)
        const isJpgOrPng = newFileList.type === 'image/jpeg' || newFileList.type === 'image/png' || newFileList.type === 'image/jpg'
        const isLt2M = newFileList.size / 1024 / 1024 < 5
        if (!isJpgOrPng) {
            setFileList(null)
            message.error('Bạn chỉ có thể upload file JPG/PNG/JPG')
        } else if (!isLt2M) {
            setFileList(null)
            message.error('Hình ảnh phải nhỏ hơn 5MB!')
        } else {
            const { status } = await userApi.uploadImage({
                file: newFileList,
                userId: user.id
            })
            if( status === 200 ) {
                setFileList(newFileList)
                headerChangeAvatarAction.changeAvatar(new Date())
                reload()
            }
        }
        setLoading(false)
        return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE
    }
    const handleRemoveUpload = async () => {
        const { status } = await userApi.removeUploadImage({
          userId: user.id
        })
        if( status === 200 ) {
          setFileList(null)
          headerChangeAvatarAction.changeAvatar(new Date())
          reload()
        }
    }

    return (
        <ComponentValidator visible={status !== "loading"} loading>
            <Spin spinning={isLoading}>
                <Upload
                    className='d-flex justify-content-center align-items-center customCssUpload'
                    listType="picture-card"
                    onRemove={handleRemoveUpload}
                    defaultFileList={fileList ? [fileList] : []}
                    beforeUpload={handleChange}
                    maxCount={1}
                >
                    {
                        !fileList && (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>
                                    Upload
                                </div>
                            </div>
                        )
                    }
                </Upload>
            </Spin>
        </ComponentValidator>
    )
}

export default AvatarProfile