import { API_TEMPLATE } from '../libs/constant'
import { get, patch, post } from '../libs/query'

const profileApi = {
  changePassword ({ oldPassword, newPassword }) {
    const path = API_TEMPLATE.PROFILE_CHANGE_PASSWORD
    console.debug(`libs/profileApi - changePassword - path: ${path}`)
    return post(path, { oldPassword, newPassword })
  },

  detail () {
    const path = API_TEMPLATE.PROFILE_DETAIL
    return get(path)
  },

  edit ({ profile }) {
    const path = API_TEMPLATE.PROFILE_EDIT
    return patch(path, profile)
  },

  updateClassInId () {
    const path = API_TEMPLATE.PROFILE_UPDATE_CLASS_IN_ID
    console.debug(`libs/profileApi - getClassInId - path: ${path}`)
    return post(path)
  },

  userProfile () {
    const path = API_TEMPLATE.USER_PROFILE
    return get(path)
  },

  teacherSalary ({ criteria, page, size }, queryString) {
    const path = API_TEMPLATE.TEACHER_SALARY
    return post(path, { criteria, page, size, sortOrders: queryString?.sortOrders })
  },
 
  getRankTeacher (teacherId) {
    const path = `${API_TEMPLATE.RANK_TEACHER_SALARY}?teacherId=${teacherId}`
    return get(path)
  }
}

export default profileApi
