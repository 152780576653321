import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useModalState } from "src/hooks/useModalState";
import { isShowModalState } from "src/libs/browser";
import { LINK_REMIND_TUTOR, URL_TEMPLATE } from "src/libs/constant";
import Link from "next/link";

export function ModalRemindTutor() {
  const { t } = useTranslation();
  const { isModalVisible, handleClose, handleOpen } = useModalState();
  useEffect(() => {
    if (isShowModalState()) {
      handleOpen()
      document.cookie = `showModal=0; path=/`;
    }
    return () => {
      handleCloseModal()
    }
  }, [])

  const handleCloseModal = () => {
    document.cookie = `showModal=0; path=/`;
    handleClose();
  }
  
  return (
    <Modal 
      visible={isModalVisible} 
      destroyOnClose
      width={560}
      footer={null}
      className="banner-remind-teacher__container"
    >
      <div className="banner-remind-teacher">
        <div className="banner-remind-teacher__btn-close">
          <Button 
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: '#B70BEE',
              color: '#F0EDFF',
              fontWeight: '900',
              border: 'none',
              height: '35px',
              width: '35px',
            }}
            onClick={handleCloseModal}
          >X</Button>
        </div>
        <div>
          {/* <img src="/image/background-cloud.png" alt="cloud" className="background-cloud"/> */}
          <div className="banner-remind-teacher_header">
            <div style={{ textAlign: 'center', margin: '10px 0 20px 0' }}>
              <span className="text">Học sinh bạn đang dạy sẽ không tiến bộ nếu không làm</span>
            </div>
            <div className="banner-remind-teacher_header-title">
              <div className="avatar-student-image_wrap">
                <img src="/image/avatar-student-men.png" alt="avatar-student" className="avatar-student-image"/>
              </div>
              <span className="banner-remind-teacher_content">BÀI TẬP VỀ NHÀ</span>
            </div>
          </div>
        </div>
        <div className="">
          <div className="banner-remind-teacher_description">
            <p>Trong tuần 2 hàng tháng, HS các lớp Regular buổi 1 sẽ có bài tập nói</p>
            <div className="banner-remind-teacher_description-text">Hãy kiểm tra và nhắc học sinh làm bài tập nói theo kịch bản và silde Học liệu nhé!</div>
          </div>
          <div className="banner-remind-teacher_button">
            <Link href={`${URL_TEMPLATE.SESSION_TEACHING_SCHEDULE}`}>
              <button onClick={handleCloseModal} className="banner-remind-teacher_button-check">Kiểm tra ngay</button>
            </Link>
            <Link href={LINK_REMIND_TUTOR}>
              <a target="_blank" className="banner-remind-teacher_button-remind">
                <button onClick={handleCloseModal} className="btn-remind">
                  Kịch bản nhắc nhở
                </button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}
