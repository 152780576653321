import moment from "moment"
import { useMemo } from "react"
import { eventTeacherDay } from "src/libs/constant"

export function useEventTeacherDayVN() {
    const { startTime, endTime, eventLink, description } = eventTeacherDay

    const isDisplayEvent = useMemo(() => {
        const current = (new Date()).valueOf()
        const start = moment(`${startTime} 00:00`, 'DD/MM/YYYY hh:mm').valueOf()
        const end = moment(`${endTime} 23:59`, 'DD/MM/YYYY hh:mm').valueOf()
        return current > start && current < end
    }, [])

    return {
        isDisplayEvent,
        eventLink,
        description
    }
}