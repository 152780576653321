import { getCurrentTime } from 'src/libs/date';
import firebase from '../firebaseConfig'
import { getUser, getUserName } from 'src/libs/browser';

export const useTracking = () => {

    const handleTracking = (eventName, param = {}) => {
        firebase.analytics().logEvent(eventName, param);
    }

    const handleTrackingSessionStart = (eventName) => {
        firebase.analytics().logEvent(eventName, {user_name: getUserName(),session_start: getCurrentTime()});
    }

    const handleTrackingSessionEnd = (eventName) => {
        firebase.analytics().logEvent(eventName, {user_name: getUserName().userName, session_end: getCurrentTime()});
    }

    return {handleTracking, handleTrackingSessionStart, handleTrackingSessionEnd}
};