import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Form, Input, Row, Col } from 'antd'
import profileApi from '../../api/profileApi'

export default function ChangePasswordForm ({ closeModal }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)

  const onFinish = async (values) => {
    const { oldPassword, newPassword } = values
    setSubmitting(true)

    const { code, data, status } = await profileApi.changePassword({ oldPassword, newPassword })

    if (status === 200) {
      closeModal()
    } else if (status === 400) {
      if (code === 'vn.edupia.tutor.user.exception.InvalidOldPasswordException') {
        form.setFields([
          {
            name: 'oldPassword',
            errors: [data]
          }
        ])
      } else {
        form.setFields([
          {
            name: 'newPassword',
            errors: [data]
          }
        ])
      }
      setSubmitting(false)
      return
    }
    form.resetFields()
    setSubmitting(false)
  }

  const onCancel = () => {
    form.resetFields()
    closeModal()
  }

  const requiredRule = [
    {
      required: true,
      message: t('common.text.field_required')
    }
  ]

  return (
    <>
      <Form
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Form.Item name='oldPassword' label={t('common.label.old_password')} rules={requiredRule}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='newPassword'
          label={t('common.label.new_password')}
          rules={[
            requiredRule[0],
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('oldPassword') === value) {
                  return Promise.reject(new Error(t('common.text.different_password')))
                }
                return Promise.resolve()
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          label={t('common.label.confirm_password')}
          rules={[
            requiredRule[0],
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t('common.text.password_and_confirm_password_not_match')))
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Row gutter={10} justify='start'>
          <Col>
            <Form.Item>
              <Button htmlType='submit' type='primary' loading={isSubmitting}>
                {t('common.label.save')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={onCancel}>{t('common.label.cancel')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
