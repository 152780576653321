import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/messaging";

export const firebaseConfig = {
  apiKey: process.env.FIRE_BASE_CONFIG_API_KEY,
  authDomain: process.env.FIRE_BASE_CONFIG_AUTH_DOMAIN,
  // databaseURL: process.env.FIRE_BASE_CONFIG_DATABASE_URL,
  projectId: process.env.FIRE_BASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.FIRE_BASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.FIRE_BASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.FIRE_BASE_CONFIG_MESSAGING_APP_ID,
  measurementId: process.env.FIRE_BASE_CONFIG_MESSAGING_MESAUREMENT_ID
};
firebase.initializeApp(firebaseConfig);
// const analytics = firebase.analytics();
export default firebase;

