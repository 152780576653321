import { API_TEMPLATE } from '../libs/constant'
import { postWithoutToken } from '../libs/query'

const authApi = {
  login ({ userName, password }) {
    const path = API_TEMPLATE.AUTH_LOGIN
    console.debug(`libs/authApi - login - path: ${path}`)
    return postWithoutToken(path, { userName, password })
  },

  logout ({ accessToken, refreshToken }) {
    const path = API_TEMPLATE.AUTH_LOGOUT
    console.debug(`libs/authApi - logout - path: ${path}`)
    return postWithoutToken(path, { accessToken, refreshToken })
  }
}

export default authApi
