import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { CATEGORY_ACTIONS } from '../actions/categoriesAction'

const INITIAL_STATE = {
  grades: undefined
}

const CategoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATEGORY_ACTIONS.SET_CATEGORIES:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'categories',
  storage: storage
}

export default persistReducer(persistConfig, CategoriesReducer)
