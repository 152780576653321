import { useEffect, useState } from 'react'
import { getUser } from 'src/libs/browser'
import { hasRequiredItem } from 'src/libs/util'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { isFunction } from 'lodash'

export const Validator = ({ requiredPermission, requiredRoles, notPermissionRender, children }) => { // check client side only
  const { userPermissions, userRoles } = getUser()
  const hasAccess = hasRequiredItem(userPermissions, requiredPermission) || hasRequiredItem(userRoles, requiredRoles)
  if (!hasAccess) {   
    return isFunction(notPermissionRender) ? <>{notPermissionRender()}</> : null
  }
  return children
}

export function ComponentValidator ({ visible, children, loading }) { // check both client & server side
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (visible === undefined && !show) {
      setShow(true)
    }
  }, [])

  useEffect(() => {
    if (visible === true || visible === false) {
      setShow(visible)
    }
  }, [visible])

  if (!show) {
    if (loading) {
      return <Spin style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', position:'fixed' }} indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
    }
    return null
  } else return children
}
