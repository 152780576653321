export const TRACKING_EVENT_NAME = {
    NUMBER_CLICKS_TODAYCLASSSCHEDULE: 'number_clicks _todayclassschedule',
    USER_DURATION_TODAYCLASSSCHEDULE_SCREEN: "user_duration_todayclassschedule_screen",
    NUMBER_TIMES_USER_VIEWS_PREVIOUSLESSON_SCREEN: "number_times_user_views_previouslesson_screen",
    USER_DURATION_PREVIOUSLESSON_SCREEN: "user_duration_previouslesson_screen",
    NUMBER_CLICKS_BTNHOMEWORK: "number_clicks _btnhomework",
    USER_DURATION_HOMEWORK_SCREEN: "user_duration_homework_screen",
    START_TIME_PREVIOUSLESSON_SCREEN: "start_time_previouslesson_screen",
    NUMBER_TIMES_USER_VIEWS_TODAYLESSON_SCREEN: "number_times_user_views_todaylesson_screen",
    USER_DURATION_TODAYLESSON_SCREEN: "user_duration_todaylesson_screen",
    START_TIME_TODAYLESSON_SCREEN: "start_time_todaylesson_screen",
    NUMBER_TIMES_USER_VIEWS_GOTOCLASS_SCREEN: "number_times_user_views_gotoclass_screen",
    USER_DURATION_GOTOCLASS_SCREEN: "user_duration_gotoclass_screen",
    NUMBER_CLICKS_BTNGOTOCLASS: "number_clicks _btngotoclass",
    START_TIME_GOTOCLASS_SCREEN: "start_time_gotoclass_screen"
}