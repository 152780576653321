import React from 'react'
import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'
import Link from 'next/link'
import { URL_TEMPLATE } from 'src/libs/constant'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.url !== this.props.url) {
      this.setState({ hasError: false })
    }
  }

  render () {
    if (this.state.hasError) {
      return <CustomError />
    }

    return this.props.children
  }
}

function CustomError () {
  const { t } = useTranslation()
  return (
    <Result
      status='warning'
      title={t('common.text.error_during_render')}
      extra={<Button type='primary'><Link href={URL_TEMPLATE.SYSTEM_DASHBOARD}>{t('common.text.back_home')}</Link></Button>}
    />
  )
}

export default ErrorBoundary
