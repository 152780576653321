import { NOTIFICATION_API_TEMPLATE } from 'src/libs/constant/notification/templateUrl'
import { post } from 'src/libs/query'


 const notificationApi = {
    init(payload){
        return post(`${NOTIFICATION_API_TEMPLATE.INIT}`, payload)
    }
}

export default notificationApi