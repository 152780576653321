import { Button, Modal, notification } from "antd";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import notificationApi from "src/api/notificationApi";
import firebase, { firebaseConfig } from "src/firebaseConfig";

export function ModalNotificationHomeWork() {
  const router = useRouter();

  useEffect(() => {
    const initializeFirebaseMessaging = async () => {
      try {
        if (typeof window !== "undefined") {
          const messaging = firebase.messaging();
          const registration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js",
            {
              scope: "/firebase-cloud-messaging-push-scope",
            }
          );
          registration?.update();
          if (registration?.active) {
            registration.active.postMessage({
              type: "INIT_FIREBASE",
              payload: {
                ...firebaseConfig,
                apiKey: firebaseConfig.apiKey,
              },
            });
          }
          messaging
            .getToken({
              vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
              serviceWorkerRegistration: registration,
            })
            .then((currentToken) => {
              if (currentToken) {
                notificationApi.init({
                  // deviceId: "",
                  firebaseToken: currentToken,
                  deviceType: "PC",
                  channel: "EDU_TUTOR_TEACHER",
                });
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
            });

          // Xử lý khi nhận được thông báo
          messaging.onMessage((payload) => {
            openNotification(payload);
          });
        }
      } catch (err) {
        console.log(err);
      }
    };
    initializeFirebaseMessaging();
  }, []);

  const openNotification = (payload) => {
    const { title, content, url } = payload.data;
    const key = `open${Date.now()}`;

    const handleConfirm = () => {
      router.push(url);
      notification.close(key);
    };

    const btn = (
      <Button type="primary" size="small" onClick={handleConfirm}>
        Xem ngay
      </Button>
    );
    notification.open({
      message: title,
      description: content,
      btn,
      key,
      duration: 0,
      // onClose: close,
    });
  };

  return null;
}
